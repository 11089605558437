.featured {
    // flex: 2;
    // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    // // padding: 10px;


    .top {
        // // padding: 20px;
        // margin-top: 10px;
        // border: 1px solid #c9c9c9;
        // border-radius: 4px;
        // // background-color: #0052cc;
        // color: #fff;

        // p{
        //     margin-bottom: 8px;
        // }
        .attendence-col {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            .attendence-col-1 {
                p {
                    // margin-bottom: 5px;
                    font-size: 10px !important;
                }

                h4 {
                    font-size: 12px;
                    letter-spacing: 2;
                    line-height: 7px !important;

                    span {
                        font-size: 16px;
                    }
                }
            }

            .attendence-col-2 {
                display: flex;

                .btn-clock-in {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 14px;
                    background-color: #0052cc;
                    font-weight: 400;
                    border: 1px solid #0052cc;
                    padding: 6px 6px;
                    border-radius: 4px;
                    cursor: pointer;
                    text-transform: capitalize;
                    margin-left: 2px;
                    display: flex;
                    align-items: center;

                    svg {
                        font-size: 12px;
                    }

                }

                .btn-clock-out {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 12px;
                    background-color: #c80808;
                    font-weight: 400;
                    border: 1px solid #c80808;
                    padding: 6px 6px;
                    border-radius: 4px;
                    cursor: pointer;
                    text-transform: capitalize;
                    margin-left: 2px;
                    display: flex;
                    align-items: center;

                }

                .btn-clock-break {
                    text-decoration: none;
                    color: #ffffff;
                    font-size: 14px;
                    background-color: #685a48d6;
                    font-weight: 400;
                    border: 1px solid #685a48d6;
                    padding: 6px 6px;
                    border-radius: 4px;
                    cursor: pointer;
                    text-transform: capitalize;
                    margin-left: 2px;
                    display: flex;
                    align-items: center;

                }

                .total-time {
                    display: block;
                    margin-top: 8px;
                    margin-left: 25px;
                    font-weight: 600;
                    font-size: 16px;
                }

                h4 {
                    font-size: 12px;
                    letter-spacing: 2;
                    line-height: 7px !important;

                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.psection {
    padding: 5px 4px 0px 5px;
    margin-bottom: 10px;

    &:hover {
        background: #0052cc1a;
        padding: 5px 4px 0px 5px;
        border-radius: 6px;
        box-shadow: 2px 2px 2px 0px #d7d7d7;
        margin-bottom: 10px;
    }

    width: 250px;
    display: flex;

    .left-data {
        float: left;
        font-size: small;
        font-weight: 500;

        svg {
            font-size: 14px;
            margin: -2px;
            color: blue;
        }
    }

    .right-data {
        float: right;
        margin-left: 38px;
        font-size: small;
    }

    .left-dataicon {
        img {
            margin-right: 22px;
            height: 25px;
        }
    }

    .right-dataicon {
        margin-left: 0px;
        font-size: medium;
        font-family: cursive;

        p {
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            margin-bottom: 5px;
            cursor: pointer;
        }
    }
}

.out-button-group {
    text-align: right;

    .out-btn {
        display: inline-block;
        position: relative;
        cursor: pointer;
        width: 53px;
        height: 28px;
        border: 2px solid #0052cc;
        padding: 0px;
        text-decoration: none;
        text-align: center;
        color: #0052cc;
        font-size: 12px;
        font-weight: normal;
        line-height: 2em;
    }
}

.profileDetail {
    display: flex;
    align-items: center;
}

.profileIcon {
    font-size: 5px;
    margin-left: -8px;
}