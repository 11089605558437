.newCredential {
    display: flex;
    width: 100%;

    .newCredentialContainer {
        flex: 4;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 20px 10px 20px;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 6px 10px 4px 10px;
                border-radius: 4px;
                cursor: pointer;
            }
        }

        form {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 15px;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 43%;

                    .form-credencial-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        min-height: 55px;

                        h3 {
                            margin-bottom: 0;
                            margin-right: 10px;
                        }

                        span {
                            margin: 0;
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            color: #0052cc;
                            cursor: pointer;

                            svg {
                                margin-right: 5px;
                            }
                        }

                        span:hover {
                            text-shadow: 0px 1px 1px #0052cc;
                        }

                    }

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }

                .formInput20 {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 21%;

                    .form-credencial-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        min-height: 55px;

                        h3 {
                            margin-bottom: 0;
                            margin-right: 10px;
                        }

                        span {
                            margin: 0;
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            color: red;
                            cursor: pointer;

                            svg {
                                margin-right: 5px;
                            }
                        }

                        span:hover {
                            text-shadow: 0px 1px 1px red;
                        }
                    }

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }

                .formInput10 {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 25%;

                    .form-credencial-row-remove {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        min-height: 100px;

                        span {
                            margin: 0;
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            color: red;
                            cursor: pointer;

                            svg {
                                margin-right: 5px;
                            }
                        }

                        span:hover {
                            text-shadow: 0px 1px 1px red;
                        }
                    }
                }

                .formInputCheckBox {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 7%;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 4px;
                    }
                }
            }

            .btn-submit {
                width: 150px;
                height: 40px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
        }

    }
}

.itemKey {
    margin-bottom: 10px;
}

.detailItem1 {
    flex-basis: 100%;
    margin-bottom: 12px;

    .itemKey {
        font-weight: 600;
        width: 30%;
        margin-bottom: 10px;
        display: inline-block;
    }
}

.detailItem2 {
    flex-basis: 100%;
    margin-bottom: 1px;

    .itemKey {
        font-weight: 600;
        width: 30%;
        margin-bottom: 22px;
        display: inline-block;
    }
}