.email-form-template {
    .add-new-user-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .formInput {
            margin-bottom: 15px;
            box-sizing: border-box;
            flex-basis: 49%;

            label {
                display: inline-block;
                align-items: center;
                gap: 10px;
                color: #172b4d;
                margin-bottom: 6px;
            }

            input {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            select {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

            textarea {
                min-width: 100%;
                padding: 10px 10px;
                border-radius: 4px;
                border: none;
                border: 1px solid #42526e;
                box-sizing: border-box;
                background-color: #fff;
            }

        }

        .datepicker-input-custom {
            div {
                width: 100% !important;
                margin-top: 2px;
            }
        }

        .uploaded-files-list {
            flex-basis: 100%;

            div {
                ul {
                    list-style-type: none;
                    padding: 0;

                    li {
                        padding: 2px 10px;
                        background-color: rgb(221 221 221 / 61%);
                        font-size: 16px;
                        text-transform: capitalize;
                        line-height: 2;
                        border-radius: 4px;
                        margin-bottom: 10px;

                        button {
                            background: transparent;
                            border: unset;
                            outline: unset;
                            float: right;
                            margin-top: 5px;

                            svg {
                                background: #000;
                                color: #fff;
                                border-radius: 50%;
                                padding: 2px;
                                font-size: 20px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .file-attchment-icon-btn {
            flex-basis: 100%;

            span {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                line-height: 4.3;
                display: block;
                text-align: center;
                background-color: #ddd;
                color: #000;
                cursor: pointer;

                svg {
                    font-size: 30px;
                    color: #000;
                    margin-top: 10px;
                }
            }
        }


    }

    //  .attach_icon {
    //     height: "40px",
    //         width: "50px",
    //         cursor: pointer,
    //         color: blue,
    // }
    .btn-add-new {
        text-decoration: none;
        color: #ffffff;
        font-size: 16px;
        background-color: #0052cc;
        font-weight: 400;
        border: 1px solid #0052cc;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        text-transform: capitalize;
        margin-left: 10px;

        svg {
            font-size: 16px;
        }
    }

    .submit-modal-email {
        width: auto;
        padding: 10px 20px;
        display: block;
        text-align: center;
        border: none;
        background-color: #0052cc !important;
        color: white !important;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        // margin-top: 10px;
        // margin-bottom: 10px;
        text-decoration: none;
    }

    .close-modal {
        width: 100px;
        padding: 10px 20px;
        display: block;
        text-align: center;
        border: none;
        background-color: #000 !important;
        color: white !important;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 10px;
        text-decoration: none;
    }

}

.btn-add-zoom {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    background-color: transparent;
    font-weight: 400;
    border: 1px solid #fff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: capitalize;
    margin-left: 10px;

    svg {
        font-size: 16px;
    }
}
form .add-new-user-form .formInput-date input {
    min-width: 100%;
    padding: 19px 10px;
    border-radius: 4px;
    border: none;
    border: 1px solid #42526e00;
    box-sizing: border-box;
    background-color: #fff;
}