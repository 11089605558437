@import "rsuite/dist/rsuite.css";

body {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

table th {
  font-size: 16px;
  color: #42526e !important;
}

table td {
  font-size: 14px !important;
  color: #42526e;
  border-bottom: unset !important;
  text-align: left !important;
  padding: 8px 10px !important;
  vertical-align: middle !important;
}

table tbody tr:hover {
  background-color: #f4f5f7;
  cursor: pointer
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: unset !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
  font-size: 16px !important;
  color: #42526e !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-size: 14px !important;
  color: #42526e !important;
  border-bottom: unset !important;
  text-align: left !important;
  padding: 8px 10px !important;
  vertical-align: middle !important;
}

.sidebar-collapse {
  flex: 0;
}

.sidebar-collapse .top .logo img {
  margin-right: 0;
}

.sidebar-collapse .pro-item-content,
.sidebar-collapse .pro-arrow-wrapper,
.sidebar-collapse .logo-text {
  display: none;
}

.sidebar-collapse .pro-sidebar {
  width: 70px;
  min-width: 70px;
}

.sidebar-collapse:hover .pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
}

.sidebar-collapse:hover .pro-item-content,
.sidebar-collapse:hover .pro-arrow-wrapper,
.sidebar-collapse:hover .logo-text {
  display: block;
}

.sidebar-collapse:hover .top .logo img {
  margin-right: 10px;
}

/* .sidebar-collapse .pro-sidebar .pro-menu .pro-menu-item .pro-inner-item:hover .pro-item-content{
  display: block;
  position: absolute;
  left: 100%;
  z-index: 999;
  background-color: #fff;
  color: #42526e;
} */

/* .sidebar-collapse .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
  overflow-y: unset;
overflow-x: unset;
} */
.master-table-body tr td:first-child {
  font-weight: 600;
  text-align: left !important;
}


.master-table-body tr td {
  font-weight: 600;
  text-align: center !important;
}

.css-1sjjn1c-MuiDataGrid-panelContent {
  overflow: inherit !important;
}

.css-emku8u {
  overflow: inherit !important;
}

.css-1e2bxag-MuiDataGrid-root {
  height: 700px !important;
}

.css-iclwpf {
  height: 700px !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
  background-color: #0052cc61 !important;
}

.css-iclwpf .MuiDataGrid-row.Mui-selected {
  background-color: #0052cc61 !important;
}

.ellips-css-data {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: block;
}

.searchWrapper {
  border: unset !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.pro-sidebar::-webkit-scrollbar {
  width: .4em;
  display: none;
}

.pro-sidebar:hover::-webkit-scrollbar {
  display: block;
}

.pro-sidebar::-webkit-scrollbar,
.pro-sidebar::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}

.pro-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .2);
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.custom-meeting-table .MuiDataGrid-row {
  min-height: 85px !important;
}

.custom-meeting-table .MuiDataGrid-row .MuiDataGrid-cell {
  min-height: 85px !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  height: 39.5px !important;
}

.rs-picker-date-menu {
  z-index: 9999 !important;
}

.css-pdct74-MuiTablePagination-selectLabel {
  display: none;
}

.css-levciy-MuiTablePagination-displayedRows {
  display: none;
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  order: 2;
  border: 1px solid;
  border-radius: 4px;
}

.custom-pagination-class {
  display: flex;
  justify-content: center;
}

.total-page-class {
  color: #555252;
}

/* .MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root {
  margin-left: 4px !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  position: static !important;
}

.sidebar-collapse:hover .pro-sidebar {
  overflow: visible !important;
} */

/* .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item{
  padding:0!important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding:0!important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul li {
 border-bottom:1px solid rgb(212 212 212);
  border-left: 1px solid #d1d1d1!important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(310deg);
    border-color: #0052cc!important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul div > ul:has(#resume-source){
height:515px;
overflow-y: scroll;

}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul div > ul::-webkit-scrollbar {
  width: .4em;
  display: none;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul div > ul:hover::-webkit-scrollbar {
  display: block;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul div > ul::-webkit-scrollbar,
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul div > ul::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul div > ul::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .2);
} */

/* this css apply server dev ui pagination */
.css-1chpzqh {
  display: none;
}

.css-rmmij8 {
  order: 2
}

.css-1cccqvr {
  border: 1px solid gray !important;
  border-radius: 2px !important;
}

.rtms-logo {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

span.logo-text {
  color: #2b548a;
  font-size: 24px;
  font-weight: 700;
  margin-left: 5px;
}

.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-menu {
  z-index: 1200;
}

.MuiDataGrid-row.Mui-odd {
  background-color: #f2f5fa;
}

/* Mui Color Input */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  border: 1px solid #6d6b6b;
  height: 44px;
}

/* agentCallPerformance css */
.hourly-status {
  display: flex;
  justify-content: end;
  align-items: end;
  color: '#606860';
  font-weight: bold;
  padding-bottom: 3px;
}

.hourly-status .btn {
  background: rgb(0, 82, 204);
  color: white;
  border-radius: 5px;
  padding: 4px;
  margin-right: 6px;
}

.MuiDataGrid-row.Mui-odd {
  background: white
}

.MuiDataGrid-row.Mui-even {
  background: #F2F5FA
}
.css-1ugvtj4-MuiContainer-root::-webkit-scrollbar {
  width: 8px;
  display: none;
}

.css-1ugvtj4-MuiContainer-root::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}

.css-1ugvtj4-MuiContainer-root::-webkit-scrollbar-thumb {
  background: rgb(123, 213, 213);
  background: linear-gradient(140deg, rgba(123, 213, 213, 1) 39%, rgba(120, 127, 246, 1) 100%);
}

.headerTitleName {
  font-weight: 500 !important;
  font-size: 25px !important;
  font-weight: 500 !important;
  color: #42526e !important;
}
