.container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 15px;
}

.container.responsive {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 15px;
}

.container .card {
    position: relative;
    width: 260px;
    height: 275px;
    margin: 0 auto;
    background: #fff;
    transition: .5s all;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12);
}

.container .card .face {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .card .face.face1 {
    box-sizing: border-box;
    padding: 20px;
}

.container .card .face.face1 :nth-child(1) .container .card .face.face1 h2 {
    margin: 0;
    padding: 0;
}

.container .card .face.face2 {
    top: 0;
    background: #f0f9ff;
    z-index: 2;
    transition: 0.5s;
    border: .1px solid rgba(0, 0, 0, 0.12)
}


.container .card:hover .face.face2 {
    height: 60px;
}

.container .card:focus-within {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
    margin-top: 4px;
}

/* .container .card:hover .menuHeading {
    opacity: 0
    font-size: 16px !important;
} */

.container .card .face.face2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    /* background: rgba(255, 255, 255, 0.1); */
}

.container .card .face.face2 .uniquneMenuIcon {
    margin: 0;
    padding: 0;
    font-size: 10em;
    /* color: #fff; */
    transition: 0.5s;
    width: 100px;
    text-shadow: 0 2px, 5px rgba(0, 0, 0, 0.2);
}

.container .card:hover .face.face2 .uniquneMenuIcon {
    font-size: 2em;
    width: 50px;
    margin-left: 5.4em;
}

.menuHeading {
    position: absolute;
    top: 19px;
    left: 18px;
    font-weight: 300 !important;
    color: #363636 !important;
    font-size: 20px !important;
    transition: all 0.5s !important
}

.content {
    height: 77%;
    width: 100%;
    overflow-y: scroll;
    position: absolute;
    top: 62px;
}

.content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
}

.content::-webkit-scrollbar {
    width: 8px;
    height: 8px;

}

.content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #f5f5f5;
}

.innerHeading {
    background: #fff !important;
    position: sticky;
    top: 0px;
    padding: 9px 0px 0px 10px;
    z-index: 1;
}

.subMenuContainer {
    display: grid !important;
    grid-template-columns: repeat(5, 1fr) !important;
    grid-auto-rows: minmax(36px, auto) !important;
    grid-gap: 7px !important;
    margin: 15px !important;
    padding: 5px !important;
    background: cornsilk;
    border: 1px solid #ffd324;
    border-radius: 5px;
}

.subMenuContainer.responsive {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-auto-rows: minmax(36px, auto) !important;
    grid-gap: 7px !important;
    margin: 15px !important;
    padding: 5px !important;
    background: cornsilk;
    border: 1px solid #ffd324;
    border-radius: 5px;
}

.navBarMenu {
    position: relative;
}

.submenuBorder:after {
    content: '';
    border: 1.1px solid black;
    display: flex;
    top: 11px;
    position: relative;
}