// .sidebar-collapse {
//     .pro-sidebar{
//         position: sticky;
//         top: 0px !important;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         max-height: 90vh;
//         overflow-y: scroll;
//         height: unset !important;
//     }

// }
// .sidebar{
//     flex: 1;
//     border-right: 0.5px solid rgb(230,227,227);
//     min-height: 100vh;
//     background-color: white;
//     margin-left: 0px;


//     .top{
//         height: 60px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         border-bottom: 0.5px solid rgb(230,227,227);
//         position: sticky;
//         top: 0px;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         max-height: 90vh;
//         overflow-y: auto;
//         z-index: 99;
//         background-color: #ffffff;

//     .logo{
//         font-size: 20px;
//         font-weight: bold;
//         color: #42526e;
//         display: flex;
//         align-items: center;
//         flex-direction: row;
//         img{
//             height: 30px;
//             margin-right: 10px;
//         }
//     }

//     }
//     .pro-sidebar{
//         position: sticky;
//         top: 50px;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         max-height: 90vh;
//         overflow-y: scroll;
//         height: unset;
//         z-index: 99 !important;
//     }



//         .center{
//             padding-left: 10px;

//             ul{
//                 list-style: none;
//                 margin: 0;
//                 padding: 0;

//                 .title{
//                     font-size: 10px;
//                     font-weight: bold;
//                     color: #999;
//                     margin-top: 15px;
//                     margin-bottom: 5px;
//                 }

//                 li {
//                     display: flex;
//                     align-items: center;
//                     padding: 5px;
//                     cursor: pointer;

//                     &:hover{
//                         background-color: #deebff;
//                         span{
//                             color: #0052cc;
//                         }
//                     }

//                     .icon{
//                         font-size: 22px;
//                         color: #42526e;
//                     }

//                     span{
//                         font-size: 15px;
//                         font-weight: 500;
//                         color: #42526e;
//                         margin-left: 10px;
//                     }
//                 }
//             }
//         }

//         .bottom{
//             display: flex;
//             margin: 10px;


//             .colorOption{
//                 width: 20px;
//                 height: 20px;
//                 border-radius: 5px;
//                 border: 1px solid #7451f8;
//                 cursor: pointer;
//                 margin: 5px;

//                 &:nth-child(1){
//                     background-color: whitesmoke;
//                 }
//                 &:nth-child(2){
//                     background-color: #333;
//                 }
//             }
//         }
// .pro-sidebar {
//     .pro-sidebar-inner {
//         background-color: #ffffff;
//     }

// .pro-menu {
// .pro-menu-item {

// font-size: 16px;
// color: #42526e !important;
// .pro-inner-item:focus {
//     background: rgb(123, 213, 213);
//     background: linear-gradient(140deg, rgba(123, 213, 213, 1) 39%, rgba(120, 127, 246, 1) 100%) !important;
// }

// .pro-icon-wrapper {
//     width: 30px;
//     min-width: 30px;
//     height: 30px;
// }

// }

// .active {
//     color: #0052cc !important;
//     background: #c0d9ff;
// }

//         ul {
//             .pro-sub-menu {
//                 .pro-inner-list-item {
//                     position: relative;
//                     background-color: #3d3d3d;
//                     color: #ddd;
//                     left: 271px;
//                     width: 271px;
//                     margin-top: -39px
//                 }
//             }
//         }

//     }

// }

// }

// .MuiTooltip-popper{
//     margin-left: -20px!important;
// }


// .pro-sidebar > .pro-sidebar-inner{

// }
.pro-sidebar {
    height: 100vh;
}

.pro-sidebar>.pro-sidebar-inner {
    background: #192734 !important;
}

.pro-sidebar ::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.pro-sidebar ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.pro-sidebar ::-webkit-scrollbar-thumb {
    background: rgb(123, 213, 213);
    background: linear-gradient(140deg, rgba(123, 213, 213, 1) 39%, rgba(120, 127, 246, 1) 100%);
}

/* Handle on hover */
.pro-sidebar ::-webkit-scrollbar-thumb:hover {
    background: rgb(123, 213, 213);
    background: linear-gradient(140deg, rgba(123, 213, 213, 1) 39%, rgba(120, 127, 246, 1) 100%);
}

.pro-menu-item.pro-sub-menu.open> {
    .pro-inner-item:nth-child(1) {
        background: rgb(123, 213, 213);
        background: linear-gradient(21deg, rgb(123, 213, 213) 17%, rgb(120, 127, 246) 77%) !important;
        font-weight: 500;
        color: #000 !important;
    }
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:focus {
    color: #fff !important
}

.pro-menu-item.pro-sub-menu.open> {
    .pro-inner-item:nth-child(1):focus {
        background-color: #6400e7c0 !important;
    }
}

li.pro-menu-item.pro-sub-menu.open>.react-slidedown .pro-inner-list-item {
    // background: #6c00e7 !important;
    background-color: #00162a !important;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
    // background-color: #6400e7c0 !important;
    background: #1c3854 !important;
    
}

.pro-menu-item>.pro-sub-menu:focus {
    background-color: #6400e7c0 !important;
}

.pro-menu-item>.pro-sub-menu:hover {
    color: #fff !important
}

.pro-menu-item>.pro-inner-item:focus {
    background-color: #6400e7c0 !important;
}

.pro-menu-item:hover {
    background: #6c00e7a1 !important;
}

.pro-sidebar.collapsed .pro-menu>ul>.pro-menu-item.pro-sub-menu>.pro-inner-list-item>.popper-inner {
    background-color: #192734 !important;

    .react-slidedown.pro-inner-list-item {
        background: #1c3854 !important;

        .react-slidedown.pro-inner-list-item {
            background-color: #000f2f !important;
        }
    }

}


.pro-menu-item.active {
    background: #6c00e7 !important;
    color: #fff !important;
    font-weight: 600 !important;
}

li.pro-menu-item.pro-sub-menu.open>.react-slidedown .pro-inner-list-item .react-slidedown.pro-inner-list-item {
    background-color: #14212cfd !important;
}