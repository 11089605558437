.new-hiring {
    display: flex;
    width: 100%;

    .new-hiring-container {
        flex: 4;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 20px 10px 0px;
            width: 100%;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                border-radius: 4px;
                cursor: pointer;
            }
        }

        .form-hiring {
            margin: 20px 20px 10px 20px;
            // -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 32%;
                    color: #000;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }

                .uploaded-files-list {
                    flex-basis: 100%;

                    div {
                        ul {
                            list-style-type: none;
                            padding: 0;

                            li {
                                padding: 2px 10px;
                                background-color: rgb(221 221 221 / 61%);
                                font-size: 16px;
                                text-transform: capitalize;
                                line-height: 2;
                                border-radius: 4px;
                                margin-bottom: 10px;

                                button {
                                    background: transparent;
                                    border: unset;
                                    outline: unset;
                                    float: right;
                                    margin-top: 5px;

                                    svg {
                                        background: #000;
                                        color: #fff;
                                        border-radius: 50%;
                                        padding: 2px;
                                        font-size: 20px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }

                .file-attchment-icon-btn {
                    flex-basis: 100%;

                    span {
                        height: 50px;
                        width: 50px;
                        border-radius: 50%;
                        line-height: 4.3;
                        display: block;
                        text-align: center;
                        background-color: #ddd;
                        color: #000;
                        cursor: pointer;

                        svg {
                            font-size: 30px;
                            color: #000;
                            margin-top: 10px;
                        }
                    }
                }
            }


            .btn-submit {
                width: 150px;
                height: 40px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
        }

    }
}

.submit-disabled-hiring {
    min-width: 150px;
    padding: 10px 20px;
    display: block;
    text-align: center;
    border: none;
    background-color: #706c6c !important;
    color: white !important;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
}

// .editButton {
//     color: #0052cc;
//     font-size: 16px;
//     display: block;

//     svg {
//         font-size: 16px;
//         margin-right: 5px;
//     }
// }

// .deleteButton {
//     color: #cc0000 !important;
//     font-size: 16px;
//     display: block;

//     svg {
//         font-size: 16px;
//         margin-right: 5px;
//     }
// }

// .viewButton{
//     color: #11cc00 !important;
//     font-size: 16px;
//     display: block;

//     svg {
//         font-size: 16px;
//         margin-right: 5px;
//     }
// }

.revised-table-header th {
    font-weight: 600;
    color: #575757!important;
    font-size: 14px;
    text-align-last: center;
    padding-right: 12px;
}