.multilevelBoxMenu {
    display: block;
    justify-content: space-between;
    // width: 100%;
    margin: 0px 5px;
}

.headerMenu {
    height: 47px !important;
    border-radius: 0 !important;
    top: 2px !important;
    margin-left: 10px !important;
}

.headerMenu:focus {
    border-bottom: 2.3px solid black !important;
}