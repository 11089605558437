.report {
    display: flex;
    width: 100%;

    .reportContainer {
        flex: 6;

        .top-headings {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px 0px 10px 0px;

            h3 {
                font-size: 25px;
                color: #42526e;
            }

            .btn-add-new {
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                background-color: #0052cc;
                font-weight: 400;
                border: 1px solid #0052cc;
                padding: 10px 20px;
                border-radius: 4px;
                cursor: pointer;
                font-weight: bold;
                text-transform: capitalize;
                margin-left: 10px;

                svg {
                    font-size: 16px;
                }
            }
        }

        .view-details-row {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 20px;

            .detailItem {
                flex-basis: 32%;
                margin-bottom: 20px;

                .itemKey {
                    width: 30%;
                    display: inline-block;
                }

                .itemKey2 {
                    font-weight: 600;
                }
            }
        }

        .top {
            padding: 20px;
            display: flex;
            gap: 20px;

            .left {
                flex: 1;
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
                box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
                padding: 20px;
                position: relative;


                .itemImg {
                    width: 100%;
                    height: unset;
                    border-radius: 8px;
                    object-fit: cover;
                }


            }
        }



        form {
            margin: 20px 20px 10px 20px;
            -webkit-box-shadow: 2px 4px 10 px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;

            .add-new-user-form {

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

                .formInput {
                    margin-bottom: 15px;
                    box-sizing: border-box;
                    flex-basis: 30%;

                    label {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        color: #172b4d;
                        margin-bottom: 6px;
                    }

                    input {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    select {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }

                    textarea {
                        min-width: 100%;
                        padding: 10px 10px;
                        border-radius: 4px;
                        border: none;
                        border: 1px solid #42526e;
                        box-sizing: border-box;
                        background-color: #fff;
                    }
                }


            }

            .btn-submit {
                width: 150px;
                padding: 10px 20px;
                display: block;
                text-align: center;
                border: none;
                background-color: #0052cc;
                color: white;
                border-radius: 4px;
                font-weight: bold;
                cursor: pointer;
                margin-top: 10px;
                text-decoration: none;
            }
        }

        .filter-btn-primary {
            background-color: #0052cc;
            color: #ffffff;
            padding: 6px 15px 7px 15px;
            border-radius: 6px !important;
            height: 36px;
            margin-left: 8px;
        }

        .filter-btn-primary {
            background-color: #0052cc;
            color: #ffffff;
            padding: 6px 15px 7px 15px;
            border-radius: 6px !important;
            height: 36px;
            margin-left: 8px;
        }

        .advance-search-input {
            justify-content: space-between;
            display: flex;
            width: 100%;
            margin-top: 30px;
            margin-bottom: 32px;

            div {
                .custom-input-box-field {
                    padding: 8px 8px;
                    border-radius: 4px;
                    border: 1px solid #ddd;
                    box-sizing: border-box;
                }
            }
        }
    }



}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    margin-top: 8px !important;
    margin-right: 10px !important;
}

.cellWithDownloadUrl {
    cursor: pointer;
    color: #135fea;
}

.cellWithDownloadUrl:hover {
    text-decoration: underline;
}

.cellWithSkillName {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cellWithCompaniesName {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.hover-call:hover {
    color: #0052cc;
 }
 .date-picker{
    margin-top: 5px !important;
    vertical-align: top;
    justify-content: flex-end;
    .MuiInputBase-root{
        height: 36px !important;
        margin-right: 10px;
        .MuiInputBase-input{
            font-size: 14px !important;
        }
    }
 }
 .date-range-picker{
    margin-top: 12px !important;
    .rs-picker{
        .rs-stack-item{
            span{
                color:#4f4d4d!important;
            }
        }
    }
 }
 .select-resource-drop{
    margin-top: 12px !important;
    .MuiInputBase-root{
        height: 36px !important;
        padding: 0.5px 60px 7.5px 5px!important;
    }
    .MuiFormControl-root{
        .MuiFormLabel-root {
            margin-top: -9px !important;
        }
    }
    .MuiInputBase-input{
        padding-right: 60px!important;
     }
 }
 .table-body-row-cell {
    text-align: center !important;
}