    .loader1 {
      width: 1rem;
      height: 1rem;
      border: 2px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

    .loaderMd {
      width: 2rem;
      height: 2rem;
      border: 4px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

    .loaderLg {
      width: 3rem;
      height: 3rem;
      border: 6px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

    .loaderXl {
      width: 4rem;
      height: 4rem;
      border: 8px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

    .loader48 {
      width: 48px;
      height: 48px;
      border: 5px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

    .loader25 {
      width: 25px;
      height: 25px;
      border: 3px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 